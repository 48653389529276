import React from 'react';

const UnauthorizedPage = () => {
  return (
    <div>
      <h1>Unauthorized</h1>
      <p>You do not have access to this page.</p>
    </div>
  );
};

export default UnauthorizedPage;
